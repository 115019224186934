import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Ratio } from "react-bootstrap";
import { colors } from "../Utilities/textBgColors";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function ProjectCategoryView({ categoryName, heading }) {
    const [projects, setProjects] = useState([]);
    const [descriptionBGColor, setDescriptionBGColor] = useState("");
    const [mediaFrameColor, setMediaFrameColor] = useState("");

    const getRandomColor = (excludedColor = null) => {
        const colorValues = Object.values(colors);
        const availableColors = excludedColor ? colorValues.filter(color => color !== excludedColor) : colorValues;
        return availableColors[Math.floor(Math.random() * availableColors.length)];
    };

    useEffect(() => {
        axios.get(`${backendUrl}/api/category/${categoryName}/`)
            .then(response => setProjects(response.data))
            .catch(error => console.error(`Error fetching ${categoryName} projects!`, error));

        const descriptionColor = getRandomColor();
        setDescriptionBGColor(descriptionColor);

        const mediaColor = getRandomColor(descriptionColor);
        setMediaFrameColor(mediaColor);
    }, [categoryName]);

    const renderProjects = () => {
        return projects.map(project => (
            <Row key={project.id} className="mb-5 project-row">
                <Col lg={12} className="title-col p-0">
                    <div className="title-container pb-3 pt-5" style={{ backgroundColor: descriptionBGColor }}>
                        <h2 className="text-white m-0 ms-5">{project.title}</h2>
                    </div>
                </Col>
                <Col lg={4} className="description-col p-0" style={{ backgroundColor: descriptionBGColor }}>
                    <div className="description-container py-3 px-5">
                        <p className="text-white text-justified">{project.description}</p>
                    </div>
                    <div className="skills-container py-2 px-5">
                        <h4 className="text-white">Skills Used:</h4>
                        <p className="text-white text-justified">{project.skills?.map(skill => skill.name).join(", ")}</p>
                    </div>
                    {project.external_link && (
                        <div className="link-container py-2 px-5">
                            <h4 className="text-white">Project Link:</h4>
                            <p>
                                <a className="text-white" href={project.external_link} target="_blank" rel="noopener noreferrer">{project.external_link}</a>
                            </p>
                        </div>
                    )}
                    {project.github_link && (
                        <div className="link-container py-2 px-5">
                            <p>
                                <a className="text-white" href={project.github_link} target="_blank" rel="noopener noreferrer">
                                    <img src="../media/media/github-mark-white.png" alt="GitHub Octocat Logo" class="img-fluid" />
                                </a>
                            </p>
                        </div>
                    )}
                </Col>
                <Col lg={8} className="media-col p-0">
                    {project.media?.map(media => (
                        <div key={media.id} className="media-container media-border pt-2 ps-2" style={{ borderTop: `4rem solid ${mediaFrameColor}`, borderLeft: `3rem solid ${mediaFrameColor}` }}>
                            {media.media_type === 'image' ? (
                                <figure>
                                    <img src={`${backendUrl}${media.file}`} alt={media.caption || "Project image"} className="img-fluid full-width-image" />
                                    <figcaption style={{ backgroundColor: mediaFrameColor }}>{media.caption || "Project image"}</figcaption>
                                </figure>
                            ) : (
                                <Ratio aspectRatio="16x9">
                                    <video src={`${backendUrl}${media.file}`} controls className="w-100"></video>
                                </Ratio>
                            )}
                        </div>
                    ))}
                </Col>
            </Row>
        ));
    };

    return (
        <Container>
            <Row>
                <Col lg={12} className="p-0">
                    <h1 className="mb-4 text-end page-heading">{heading}</h1>
                </Col>
            </Row>
            {renderProjects()}
        </Container>
    );
}

export default ProjectCategoryView;