import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Home() {
    return (
        <Container className="py-5 home-container">
            <Row>
                <Col md={12} className="text-center">
                    <h1 className="mb-3">Hi, I’m Dex Fitch.</h1>
                    <img src="../media/media/me.png" alt="Photo of Dex Fitch" class="img-fluid self-portrait mb-3" />
                    <p className="lead">I’m a creative technologist, designer, and educator who blends storytelling with strategy across web, print, and motion.</p>
                    <p>From full-stack apps and portfolio sites to animations and outreach campaigns, I create human-centered work that’s functional, accessible, and visually compelling.</p>
                </Col>
            </Row>

            <Row className="text-center mt-5 links-section">
                <Col>
                    <p>See me around town!</p>
                    <div className="d-flex justify-content-center gap-3 mt-3 flex-wrap">
                        <a href="https://github.com/dexterfitch" target="_blank" rel="noopener noreferrer" className="btn btn-outline-light">GitHub</a>
                        <a href="https://www.linkedin.com/in/dexterfitch/" target="_blank" rel="noopener noreferrer" className="btn btn-outline-light">LinkedIn</a>
                        <a href="https://www.instagram.com/hexadexymal/" target="_blank" rel="noopener noreferrer" className="btn btn-outline-light">Instagram</a>
                        <a href="https://www.redbubble.com/people/dexterrr/shop" target="_blank" rel="noopener noreferrer" className="btn btn-outline-light">Store</a>
                        <a href="mailto:fitch.dexter@gmail.com" className="btn btn-outline-light">Email</a>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;